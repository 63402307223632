import { currencyFormatter } from '../utils/numberFormat';
import { useMemo, useEffect } from 'react';
import { ReceivalItemDto } from '../../apiClient/generated';
import styles from './OrderPDF.module.css';
import { OrderToDisplay, Product } from '../../types/order.types';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import PricePerPackOrderPDF from './PricePerPackOrderPDF';
interface SingleItemRowProps {
    product: Product;
    orderToDisplay: OrderToDisplay;
    lineItem?: ReceivalItemDto;
    rowNumber?: number;
    sortedProductsLength?: number;
    categoryRowNumber: number;
    showPrice?: boolean;
}

export const OrderPDFReceivedProductRow: React.FC<SingleItemRowProps> = ({
    product,
    orderToDisplay,
    lineItem,
    rowNumber,
    showPrice,
}) => {
    const { itemName, itemNumber, unitOfMeasure } = product;

    const rfqLineItem = useMemo(
        () => orderToDisplay.rfq.lineItems.find((item) => item.itemNumber === lineItem?.itemNumber),
        [orderToDisplay],
    );

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const roundedPrice = lineItem && Math.round(lineItem?.price * 100) / 100;
    const receivedTotal =
        roundedPrice && lineItem.quantity
            ? Math.round(roundedPrice * lineItem?.quantity * 100) / 100
            : 0;

    return (
        <div className={styles.tableRow}>
            <div className={[styles.tableCell, styles.s1].join(' ')}>{rowNumber}</div>

            <div className={styles.tableCell}>
                <div className={styles.badge}>{itemNumber}</div>
                {itemName || lineItem?.itemName}
            </div>
            <div className={[styles.tableCell, styles.s2].join(' ')}>
                {rfqLineItem?.unitOfMeasure}
            </div>
            {showPrice && (
                <div className={[styles.tableCell, styles.s2].join(' ')}>
                    {lineItem && (
                        <PricePerPackOrderPDF
                            unitOfMeasure={lineItem?.unitOfMeasure ?? ''}
                            price={lineItem.price}
                            isEstimatedPrice={false}
                            currency={vesselCurrency}
                        />
                    )}
                    {rfqLineItem?.estimatedPrice}
                </div>
            )}
            <div className={[styles.tableCell, styles.s2].join(' ')}>
                {rfqLineItem?.quantity} {unitOfMeasure}
            </div>
            {showPrice && (
                <div className={[styles.tableCell, styles.s2].join(' ')}>
                    {currencyFormatter(receivedTotal, vesselCurrency)}
                </div>
            )}
            <div className={styles.tableCell}>{lineItem?.comment}</div>
        </div>
    );
};
