
import { currencyFormatter } from '../utils/numberFormat';
import styles from './OrderPDF.module.css';
import { LineItem, OrderToDisplay, Product } from '../../types/order.types';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import PricePerPackOrderPDF from './PricePerPackOrderPDF';

interface SingleProductRowProps {
    product: Product;
    orderToDisplay: OrderToDisplay;
    lineItem?: LineItem;
    rowNumber?: number;
    sortedProductsLength?: number;
    categoryRowNumber: number;
    showPrice?: boolean;
}

export const OrderPDFSingleProductRow: React.FC<SingleProductRowProps> = ({
    product,
    orderToDisplay,
    lineItem,
    rowNumber,
    showPrice,
}) => {
    const productRowDetails = {
        itemName: product.itemName || lineItem?.itemName,
        itemNumber: product.itemNumber || lineItem?.itemNumber,
        isEstimatedPrice: !lineItem?.confirmedPrice,
        price: lineItem?.confirmedPrice ?? lineItem?.estimatedPrice ?? product.estimatedPrice,
        packSize:
            lineItem?.confirmedPackSize ?? lineItem?.estimatedPackSize ?? product.estimatedPackSize,
        unitOfMeasure: lineItem?.unitOfMeasure ?? product.unitOfMeasure,
    };

    const { itemName, itemNumber, isEstimatedPrice, price, packSize, unitOfMeasure } =
        productRowDetails;

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const itemQuantityFromDb =
        orderToDisplay.rfq.lineItems.find((item) => item.itemNumber === itemNumber)?.quantity ?? 0;

    const getTotalPrice = () => {
        if (!itemQuantityFromDb) {
            return <></>;
        }

        const roundedPrice = Math.round(price * 100) / 100;
        const totalPrice = Math.round(roundedPrice * itemQuantityFromDb * 100) / 100;

        if (totalPrice === 0) {
            return <></>;
        }

        return (
            <>{`${isEstimatedPrice ? '≈' : ''} ${currencyFormatter(totalPrice, vesselCurrency)}`}</>
        );
    };

    return (
        <>
            <div className={styles.tableRow}>
                <div className={[styles.tableCell, styles.s1].join(' ')}>{rowNumber}</div>

                <div className={styles.tableCell}>
                    <div className={styles.badge}>{itemNumber}</div>
                    {itemName || lineItem?.itemName}
                </div>

                <div className={[styles.tableCell, styles.s2].join(' ')}>{unitOfMeasure}</div>
                {showPrice && (
                    <div className={[styles.tableCell, styles.s2].join(' ')}>
                        <PricePerPackOrderPDF
                            unitOfMeasure={unitOfMeasure}
                            price={price}
                            isEstimatedPrice={isEstimatedPrice}
                            currency={vesselCurrency}
                        />
                    </div>
                )}
                <div className={[styles.tableCell, styles.s2].join(' ')}>
                    {' '}
                    {itemQuantityFromDb} &nbsp;{unitOfMeasure ?? ''}
                </div>
                {showPrice && (
                    <div className={[styles.tableCell, styles.s2].join(' ')}>
                        {' '}
                        {getTotalPrice()}{' '}
                    </div>
                )}
                <div className={styles.tableCell}>
                    {packSize || ''}
                    {lineItem?.comment}
                </div>
            </div>
        </>
    );
};

